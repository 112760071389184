import React from 'react';
import IntroBlock from '../IntroBlock';
import FirstCinemaTop from '../cinemagraphs/FirstCinemaTop';
import { imgBrand, alt } from '../../lib/brand';
import styles from './styles.module.less';

var gettingStartedIcon = imgBrand('Icon_Getting_started_small.png', 'FirstTimeHomebuyer');
var loanTypesIcon = imgBrand('Icon_Homeloan_Small.png', 'FirstTimeHomebuyer');
var calculateIcon = imgBrand('Icons_Calculate_Active.png', 'FirstTimeHomebuyer');
var findTeamIcon = imgBrand('Icons_FindTeam_Active.png', 'FirstTimeHomebuyer');

const FirstTimeHomebuyer = props => {
   const header = (
      <span>
         First-time
         <br /> homebuyer
      </span>
   );
   const par = (
      <>
         Buying your first home is a major event. And it’s normal to feel a simultaneous sense of <em>excitement</em>{' '}
         and <em>where do I start!?</em> So let us add some clarity: start right here with these basic first-time
         homebuyer tips and tools.
      </>
   );

   return (
      <div className={styles.FirstTimeHomebuyer}>
         <FirstCinemaTop />
         <IntroBlock header={header} par={par} />
         <section className={styles.FirstTimeHomebuyer}>
            <div className={styles.subcontainer}>
               <a href="#getstarted">
                  <div className={styles.product}>
                     <div className={styles.icon}>
                        <img src={gettingStartedIcon} alt={alt('Get Started Buying a Home - Get Started Icon')} />
                     </div>
                     <h1 className={styles.caption}>
                        Get Started
                        <br />
                     </h1>
                  </div>
               </a>
               <a href="#loantypes">
                  <div className={styles.product}>
                     <div className={styles.icon}>
                        <img
                           src={loanTypesIcon}
                           alt={alt('Learn the Different Types of Loans - Learn the Loan Types Icon')}
                        />
                     </div>

                     <h1 className={styles.caption}> Learn the Loan Types</h1>
                  </div>
               </a>

               <a href="#findyourteam">
                  <div className={styles.product}>
                     <div className={styles.icon}>
                        <img
                           src={findTeamIcon}
                           alt={alt('Find Your Bay Equity Home Loan Team - Find Your Team Icon')}
                        />
                     </div>
                     <h1 className={styles.caption}>Find Your Team</h1>
                  </div>
               </a>
               <a href="#calculate">
                  <div className={styles.product}>
                     <div className={styles.icon}>
                        <img
                           src={calculateIcon}
                           alt={alt('Calculate Your Home Loan Payment - Calculate Your Payment Icon')}
                        />
                     </div>
                     <h1 className={styles.caption}> Calculate Your Payment</h1>
                  </div>
               </a>
            </div>
         </section>
      </div>
   );
};

export default FirstTimeHomebuyer;
